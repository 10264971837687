import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./Tab.module.scss"

const Tab = ({ className, children, isVisible }) => {
  if (!isVisible) {
    return null
  }

  return <div className={cn(className, styles.tab)}>{children}</div>
}

Tab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isVisible: PropTypes.bool,
}

Tab.defaultProps = {
  className: "",
  children: undefined,
  isVisible: false,
}

export default Tab
