import React, { useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import styles from "./Tabs.module.scss"
import { animate } from "../../utilities/animate"
import sal from "sal.js"
import { Link } from "gatsby"

const Tabs = (props) => {
  const { className, items, handleOnClick, activeItemSlug, isSticky } = props

  const isTouch = typeof window !== "undefined" && "ontouchstart" in window

  // re-init is needed when <Tabs /> are placed in scrollmagic <Scene /> component
  useEffect(() => {
    sal({
      selector: "[data-animated-tabs]",
      animateClassName: "animate",
      disabledClassName: "reveal-disabled",
      threshold: 0.2,
    })
  }, [isSticky])

  return (
    <ul className={cx(styles.items, className)}>
      {items.map((item, index) => {
        return (
          <li
            className={cx(styles.item, "h5", {
              [styles.isSticky]: isSticky,
            })}
            key={index}
            data-animated-tabs=""
            {...animate({ index: index, delay: 100 })}
          >
            <Link
              to={item.path}
              className={cx(styles.link, {
                [styles.linkActive]: activeItemSlug === item.slug,
                [styles.hoverEnabled]: !isTouch,
              })}
              onClick={(event) => handleOnClick(item.path, event)}
            >
              {item.name}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string,
      to: PropTypes.string,
    })
  ).isRequired,
  activeItemSlug: PropTypes.string,
  verticalLayoutOnDesktop: PropTypes.bool,
  handleOnClick: PropTypes.func.isRequired,
  isSticky: PropTypes.bool,
}

Tabs.defaultProps = {
  items: [],
  activeItemSlug: "",
  handleOnClick: () => null,
  isSticky: false,
}

export default Tabs
