import React, { useMemo, useRef } from "react"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { Hero } from "components/hero"
import ScreenClip from "../components/screen-clip/ScreenClip"
import { graphql } from "gatsby"
import ProjectCard from "../components/sections/ventures/project-card/ProjectCard"
import Tab from "../components/sections/ventures/tab/Tab"
import Wrapper from "../components/wrapper/Wrapper"
import { Holder } from "../components/holder"
import { Controller, Scene } from "react-scrollmagic"
import { useMeasure } from "react-use"
import StickyHolder from "../components/sections/ventures/sticky-holder/StickyHolder"
import Tabs from "../components/tabs/Tabs"
import { useBreakpoint } from "../hooks/useBreakpoint"
import { useLocationQuery } from "../hooks/useQueryParams"
import animateScrollTo from "animated-scroll-to"

const heroProps = {
  isCenter: true,
  title: "Martian portfolio",
  subtitle:
    "Through our ventures and products, we’ve raised €100M+ in funding globally and helped dozens of startups get off the ground.",
}

const TabName = {
  Ventures: "ventures",
  Products: "products",
}

const Portfolio = ({ location, data }) => {
  const query = useLocationQuery()
  const tab = query.get("tab")
  const activeTab = useMemo(() => {
    if (tab === TabName.Ventures) {
      return TabName.Ventures
    }
    if (tab === TabName.Products) {
      return TabName.Products
    }

    return TabName.Ventures
  }, [tab])

  const wrapperRef = useRef(null)
  const [measureRef, { height }] = useMeasure()

  const breakpoint = useBreakpoint()
  const duration = useMemo(() => {
    if (breakpoint === "xs" || breakpoint === "sm") {
      return height + 100
    }

    return height + 150
  }, [height, breakpoint])

  return (
    <ScreenClip>
      <Layout location={location}>
        <Meta
          title={"Portfolio"}
          description={
            "We worked on over 40 startups and 100 innovation projects. Our ventures and products raised over 100M+ in funding."
          }
        />
        <Hero {...heroProps} />

        <Wrapper ref={wrapperRef}>
          <Controller>
            <Scene
              triggerHook={"onLeave"}
              duration={duration}
              pinSettings={{ pushFollowers: false }}
            >
              {(progress, event) => {
                return (
                  <StickyHolder isHidden={event.state === "AFTER"}>
                    <Tabs
                      isSticky={event.state === "DURING"}
                      activeItemSlug={activeTab}
                      items={[
                        {
                          slug: TabName.Ventures,
                          path: location.pathname + "?tab=" + TabName.Ventures,
                          name: "Ventures",
                        },
                        {
                          slug: TabName.Products,
                          path: location.pathname + "?tab=" + TabName.Products,
                          name: "Products",
                        },
                      ]}
                      handleOnClick={() => {
                        animateScrollTo(wrapperRef.current, {
                          maxDuration: 4000,
                          minDuration: 1000,
                          cancelOnUserAction: false,
                        }).then(() => {
                          // move 1px down so the sticky header gets hidden
                          animateScrollTo(wrapperRef.current, {
                            verticalOffset: 1,
                          })
                        })
                      }}
                    />
                  </StickyHolder>
                )
              }}
            </Scene>
          </Controller>

          <Holder ref={measureRef}>
            <Tab isVisible={activeTab === TabName.Ventures}>
              <ProjectCard
                name={"Cito"}
                url={"https://www.cito.ai/"}
                urlText={"cito.ai"}
                image={{
                  fluid: data?.citoImage?.childImageSharp?.fluid,
                  alt: "Cito image",
                }}
                imageMobile={{
                  fluid: data?.citoImageMobile?.childImageSharp?.fluid,
                  alt: "Cito image",
                }}
                title={"Fully digital courier service"}
                text={
                  "The digital transport platform for courier services, time-critical deliveries, and the direct transport of goods. Fast, digital, consistent."
                }
              />
              <ProjectCard
                name={"Urbify"}
                url={"https://www.urbify.com/"}
                urlText={"urbify.com"}
                image={{
                  fluid: data?.urbifyImage?.childImageSharp?.fluid,
                  alt: "Urbify image",
                }}
                imageMobile={{
                  fluid: data?.urbifyImageMobile?.childImageSharp?.fluid,
                  alt: "Urbify image",
                }}
                title={"Next level same-day and next-day deliveries"}
                text={
                  "Urban delivery platform for e-commerce & e-grocery that provides same-day & next-day deliveries with best-in-class service quality."
                }
              />
              <ProjectCard
                name={"FPMM Venture Builder"}
                disabledUrl={"coming soon"}
                image={{
                  fluid: data?.fpmmImage?.childImageSharp?.fluid,
                  alt: "Daas image",
                }}
                imageMobile={{
                  fluid: data?.fpmmImageMobile?.childImageSharp?.fluid,
                  alt: "Daas image",
                }}
                title={"Disruption-as-a-Service (DaaS) venture offering"}
                text={
                  "Building ventures in the MENAT region with local holding companies and corporates."
                }
              />
            </Tab>

            <Tab isVisible={activeTab === TabName.Products}>
              <ProjectCard
                name={"Cleverest"}
                url={"https://www.cleverestapp.com/"}
                urlText={"cleverestapp.com"}
                image={{
                  fluid: data?.cleverestImage?.childImageSharp?.fluid,
                  alt: "Cleverest image",
                }}
                imageMobile={{
                  fluid: data?.cleverestImageMobile?.childImageSharp?.fluid,
                  alt: "Cleverest image",
                }}
                title={"Digital Detox from your phone"}
                text={
                  "An app that helps you create healthy habits and focus on improving your digital well-being."
                }
              />
              <ProjectCard
                name={"Boneo"}
                image={{
                  fluid: data?.boneoImage?.childImageSharp?.fluid,
                  alt: "Boneo image",
                }}
                imageMobile={{
                  fluid: data?.boneoImageMobile?.childImageSharp?.fluid,
                  alt: "Boneo image",
                }}
                title={"Gaming meets loyalty program"}
                text={
                  "A game-like loyalty program enables drivers to compete with each other, discover secrets and earn compelling rewards."
                }
              />
              <ProjectCard
                name={"Likeminded"}
                url={"https://www.likeminded.care/de"}
                urlText={"likeminded.care"}
                image={{
                  fluid: data?.likemindedImage?.childImageSharp?.fluid,
                  alt: "Likeminded image",
                }}
                imageMobile={{
                  fluid: data?.likemindedImageMobile?.childImageSharp?.fluid,
                  alt: "Likeminded image",
                }}
                title={"The right support for your mental health"}
                text={
                  "A holistic approach for simple and individual access to mental wellbeing at work."
                }
              />
              <ProjectCard
                name={"Data 4 Life"}
                url={"https://www.data4life.care/en/"}
                urlText={"data4life.care"}
                image={{
                  fluid: data?.data4lifeImage?.childImageSharp?.fluid,
                  alt: "Data 4 Life image",
                }}
                imageMobile={{
                  fluid: data?.data4lifeImageMobile?.childImageSharp?.fluid,
                  alt: "Data 4 Life image",
                }}
                title={"Digital solutions for a healthier world"}
                text={
                  "A digital platform that helps people improve the diagnosis, treatment, prevention of diseases, and general quality of life."
                }
              />
            </Tab>
          </Holder>
        </Wrapper>
      </Layout>
    </ScreenClip>
  )
}

export const query = graphql`
  query {
    citoImage: file(relativePath: { eq: "portfolio/cito.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    citoImageMobile: file(relativePath: { eq: "portfolio/cito-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    urbifyImage: file(relativePath: { eq: "portfolio/urbify.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    urbifyImageMobile: file(
      relativePath: { eq: "portfolio/urbify-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    fpmmImage: file(relativePath: { eq: "portfolio/fpmm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    fpmmImageMobile: file(relativePath: { eq: "portfolio/fpmm-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    cleverestImage: file(relativePath: { eq: "portfolio/cleverest.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    cleverestImageMobile: file(
      relativePath: { eq: "portfolio/cleverest-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    boneoImage: file(relativePath: { eq: "portfolio/boneo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    boneoImageMobile: file(relativePath: { eq: "portfolio/boneo-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    likemindedImage: file(relativePath: { eq: "portfolio/likeminded.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    likemindedImageMobile: file(
      relativePath: { eq: "portfolio/likeminded-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    data4lifeImage: file(relativePath: { eq: "portfolio/data4life.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    data4lifeImageMobile: file(
      relativePath: { eq: "portfolio/data4life-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default Portfolio
