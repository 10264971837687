import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./StickyHolder.module.scss"
import { Holder } from "../../../holder"

const StickyHolder = React.forwardRef(
  ({ className, children, isHidden }, ref) => {
    return (
      <div
        className={cn(className, styles.wrapper, {
          [styles.isHidden]: isHidden,
        })}
        ref={ref}
      >
        <Holder className={styles.holderInner}>{children}</Holder>
      </div>
    )
  }
)

StickyHolder.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isHidden: PropTypes.bool,
}

StickyHolder.defaultProps = {
  className: "",
  children: undefined,
  isHidden: false,
}

export default StickyHolder
