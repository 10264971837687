import React, { useMemo } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./ProjectCard.module.scss"
import Img from "gatsby-image"
import LinkSvg from "./assets/link.svg"
import LinkDisabledSvg from "./assets/link-disabled.svg"
import { animate } from "../../../../utilities/animate"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"

const ProjectCard = React.forwardRef(
  (
    {
      className,
      name,
      title,
      url,
      urlText,
      disabledUrl,
      image,
      imageMobile,
      text,
    },
    ref
  ) => {
    const breakpoint = useBreakpoint()
    const renderedImage = useMemo(() => {
      if (!imageMobile) {
        return image
      }

      if (breakpoint === "xs") {
        return imageMobile
      }

      return image
    }, [image, imageMobile, breakpoint])

    return (
      <section
        className={cn(className, styles.wrapper)}
        {...animate()}
        ref={ref}
      >
        <div className={styles.header}>
          <h3 className={styles.name}>{name}</h3>
          {url && (
            <a
              className={styles.link}
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={url}
            >
              <img className={styles.icon} src={LinkSvg} alt={"Url icon"} />
              <span>{urlText}</span>
            </a>
          )}
          {disabledUrl && (
            <span className={cn(styles.disabledLink)}>
              <img
                className={styles.icon}
                src={LinkDisabledSvg}
                alt={"Url icon"}
              />
              <span>{disabledUrl}</span>
            </span>
          )}
        </div>

        <div className={styles.imageContainer}>
          <Img
            className={styles.image}
            fluid={renderedImage?.fluid}
            alt={renderedImage?.alt}
            style={{ position: "absolute" }}
            fadeIn={true}
          />
        </div>

        <div className={styles.textWrap}>
          <h4 className={styles.title}>{title}</h4>
          <p className={cn(styles.text, "h5")}>{text}</p>
        </div>
      </section>
    )
  }
)

ProjectCard.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    fluid: PropTypes.object,
  }).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  urlText: PropTypes.string,
  disabledUrl: PropTypes.string,
}

ProjectCard.defaultProps = {
  className: "",
  url: "",
  urlText: "",
}

export default ProjectCard
